<template>
    <base-layout>
        <div class="page-bulk container">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading">Bulk Pre-Qualification</h1>
                    <app-tabs v-model="tab" :tabs="tabs"></app-tabs>
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </base-layout>
</template>

<script>
import baseLayout from '@/views/layouts/base-layout'
import appTabs from '@/components/app-tabs'

export default {
    props: {
        search: { type: Object, default: () => ({ word: '', option: null })}
    },

    components: {
        baseLayout,
        appTabs,
    },

    data() {
        return {
            tabs: [
                {
                    id: 'bulk-new',
                    name: 'bulk-new',
                    title: 'New Search',
                },
                {
                    id: 'bulk-previous',
                    name: 'bulk-previous',
                    title: 'Previous Searches',
                },
            ],
        }
    },

    computed: {
        tab: {
            get() {
                const tab = this.tabs.find(tab => this.$route.name == tab.name || this.$route.name == tab.alias)

                if (!tab) {
                    this.tab = { name: 'bulk-new', replace: true }
                }

                return tab
            },

            set(tab) {
                this.$router.push({ name: tab.name, replace: true })
            }
        },
    },
}
</script>

<style lang="scss">
.page-bulk {
    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .app-tabs {
        margin-bottom: 34px;
    }
}

@media (max-width: $tablet-size) {

}

@media (max-width: $mobile-size) {
    .page-bulk {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .app-tabs {
            margin-bottom: 16px;
        }
    }
}
</style>